function toggleNavigation() {

  $(".energy-menu a:not(.children)").click(function(event) {
    var targetId = $(this).attr("href");
    // console.log(targetId);
    // console.log(targetId.includes("#kontakt"));


    if(!targetId.includes("energie") && (targetId.includes("#kontakt") || targetId.includes("#cedehaus")) ){
      $(this).attr('href', "/energie/" + targetId);
    }
    targetId = targetId.replace("pv-anlagen/", "");

    if ($(targetId).length) {
      $('html, body').animate({
          scrollTop: $(targetId).offset().top - 250
      }, 50);
    }
  });

   $("#menu-main li.children button").click(function (event) {
    event.preventDefault();
    $(this).toggleClass("open");
    $(this).next().toggleClass("open");
  });

  $('#menu-main li.children a[href="#"]').click(function (event) {
    event.preventDefault();
    $(this).next().toggleClass("open");
    $(this).next().next().toggleClass("open");
  });

  $('.navbar-toggler').click(function (event) {
    event.preventDefault();
    $(this).toggleClass("open");
    $('.menu-wrapper').toggleClass("open");

  }); 

  let currentLocation = window.location.href;
  let menuChildren = $('#menu-main').children().children('a');


  if (currentLocation != null && currentLocation.indexOf('/blog/') >= 0){

    menuChildren.each(function(){
      if (this.getAttribute("href").indexOf('/blog/') >= 0) {
        $(this).parent().addClass('active');
      } else{
        $(this).parent().removeClass('active');
      }
    });

  } else{

    menuChildren.each(function(){
      if (this.getAttribute("href").indexOf('/blog/') >= 0) {
        $(this).parent().removeClass('active');
      }
    });

  }
}
export { toggleNavigation };


/* function smoothScroll() {
  const sections = $(".block .global-wrapper");
  const navLi = $("nav .energy-menu li");

  navLi.each(function() {
    $(this).removeClass("active");
  });

  $(window).on("scroll", function() {
    var current = "";

    sections.each(function() {
      const sectionTop = $(this).offset().top;
      if ($(window).scrollTop() >= sectionTop - 100) {
        current = $(this).attr("id");
      }
    });

    navLi.each(function() {
      $(this).removeClass("active");
      if ($(this).find(`a[href*='#${current}']`).length) {
        $(this).addClass("active");
      }
    });
  });
}

export { smoothScroll }; */
