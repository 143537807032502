/* General + Third-Party */
import jQuery from 'jquery';
import '@fancyapps/fancybox';
import { lazyload } from './globals/lazyload';
import "owl.carousel";

/* Layout-Parts */
import { toggleNavigation } from './layout/header';
// import { smoothScroll } from './layout/header';

/* Blocks */
 import { accStateChange } from '../../blocks/accordion/script';
 import { heroSlider } from "../../blocks/slider-hero/script";

jQuery(document).ready(_ => {
  // General + Third-Party
  lazyload();
  accStateChange();

  // Layout-Parts
  toggleNavigation();
  // smoothScroll();

  // Blocks
  heroSlider();

});
